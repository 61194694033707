import { sendDataLayerEvent } from "./dataLayer";

const quickAdvancedSearch = document.getElementById("quick-search-advanced");
const searchResultAdvancedSearch = document.getElementById("advanced-search");
const searchResultFilter = document.getElementById(
  "advanced-search_filter-button"
);

if (quickAdvancedSearch) {
  quickAdvancedSearch.addEventListener("click", () => {
    sendDataLayerEvent("AdvancedSearchClicked");
  });
}

if (searchResultAdvancedSearch) {
  searchResultAdvancedSearch.addEventListener("click", () => {
    sendDataLayerEvent("AdvancedSearchClicked");
  });
}

if (searchResultFilter) {
  searchResultFilter.addEventListener("click", () => {
    sendDataLayerEvent("AdvancedSearchClicked");
  });
}
